import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
} from '../../../../shared/constants/ActionTypes';
import jwtAxios, {setAuthToken} from './jwt-api';
import {useNavigate} from 'react-router-dom';

const JWTAuthContext = createContext();
const JWTAuthActionsContext = createContext();

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider = ({children}) => {
  const [firebaseData, setJWTAuthData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const getAuthUser = () => {
      const token = localStorage.getItem('token');

      if (!token) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }
      setAuthToken(token);
      jwtAxios
        .get('/me')
        .then(({data}) => {
          setJWTAuthData({
            user: data,
            isLoading: false,
            isAuthenticated: true,
          });
        })
        .catch(() =>
          setJWTAuthData({
            user: undefined,
            isLoading: false,
            isAuthenticated: false,
          }),
        );
    };

    getAuthUser();
  }, []);

  const signInUser = async ({email, password}, onSuccess, onError) => {
    dispatch({type: FETCH_START});
    try {
      const {data} = await jwtAxios.post('auth/login', {
        username: email,
        password,
      });
      localStorage.setItem('token', data.jwt.access_token);
      localStorage.setItem('refreshToken', data.jwt.refresh_token);
      setAuthToken(data.jwt.access_token);
      const res = await jwtAxios.get('/me');
      setJWTAuthData({user: res.data, isAuthenticated: true, isLoading: false});
      dispatch({type: FETCH_SUCCESS});
      if (onSuccess) {
        onSuccess(res);
      }
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });

      const errMessage = error.response?.data?.message;
      if (
        errMessage ===
        'Your account is being deactivated, please contact us for support.'
      ) {
        if (onError) {
          onError({
            errorMessage: errMessage,
          });
        }
        return;
      }

      const errorMessage = 'Email or password is incorrect.';
      dispatch({
        type: FETCH_ERROR,
        payload: errorMessage,
      });
      if (onError) {
        onError({errorMessage});
      }
    }
  };

  const signUpUser = async (
    {firstName, lastName, dob, gender, email, password},
    onSuccess,
    onError,
  ) => {
    dispatch({type: FETCH_START});
    try {
      const {data} = await jwtAxios.post('auth/signup', {
        firstName,
        email,
        password,
        lastName,
        dob,
        gender,
      });
      dispatch({type: FETCH_SUCCESS});
      if (onSuccess) {
        onSuccess(data);
      }
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({type: FETCH_ERROR, payload: error.response.data?.message});
      const errorMessage = error.response.data?.message;

      if (onError) {
        onError({errorMessage});
      }
    }
  };

  const changePassword = async (payload, onSuccess, onError) => {
    dispatch({type: FETCH_START});
    try {
      const res = await jwtAxios.put('me/change-password', payload);
      dispatch({type: FETCH_SUCCESS});
      if (onSuccess) {
        onSuccess(res);
      }
    } catch (error) {
      dispatch({type: FETCH_ERROR, payload: error.response?.data?.message});
      const errorMessage = error.response?.data?.message;

      if (onError) {
        onError({errorMessage});
      }
    }
  };

  const getUserProfile = async () => {
    dispatch({type: FETCH_START});
    try {
      const res = await jwtAxios.get('/me');

      setJWTAuthData({user: res.data, isAuthenticated: true, isLoading: false});
      dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({
        type: FETCH_ERROR,
        payload: error.message || 'Something went wrong',
      });
    }
  };

  const navigate = useNavigate();

  const logout = async () => {
    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
    navigate('/signin');
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
      }}>
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          logout,
          getUserProfile,
          changePassword,
        }}>
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;

JWTAuthAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
