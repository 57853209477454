/* eslint-disable react/prop-types */
import {Table, Typography} from 'antd';
import React from 'react';
import './index.style.less';

const ObjectTable = ({data, className}) => {
  const dataSource = [];
  data?.map((x) => {
    dataSource[x.label] = x.value;
  });

  return (
    <Table
      className={`mt-8 ${className} tb-object`}
      columns={data?.map((x, index) => ({
        title: x.label,
        dataIndex: x.label,
        key: x.label + index,
        render: () => (
          <Typography.Text className='text-primary'>{x.value}</Typography.Text>
        ),
      }))}
      pagination={false}
      bordered
      dataSource={[dataSource]}
    />
  );
};

export default ObjectTable;
