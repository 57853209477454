import {Col, Row} from 'antd';
import {articlePagesConfigs} from './article';
import TermAndCondition from './article/ArticleDetailPage/TermAndCondition';
import {authRouteConfig} from './auth';
import {calendarPagesConfigs} from './calendar';
import {chatPagesConfigs} from './chat';
import {errorPagesConfigs} from './errorPages';
import Error403 from './errorPages/Error403';
import Error404 from './errorPages/Error404';
import {homePagesConfigs} from './home';
import {
  connectionChildPagesConfigs,
  connectionRootPagesConfigs,
} from './network';
import {notificationPagesConfigs} from './notification';
import {profileConfig} from './profile';
import {searchPagesConfigs} from './search';
import {supportPagesConfigs} from './support';
import SupportPage from './support/SupportPage';
import {voiceChildPagesConfigs, voiceRootPagesConfigs} from './voice';

const authorizedStructure = {
  fallbackPath: '/signin',
  unAuthorizedComponent: <Error403 />,
  routes: [
    ...articlePagesConfigs,
    ...notificationPagesConfigs,
    ...supportPagesConfigs,
    ...calendarPagesConfigs,
    ...chatPagesConfigs,
    ...searchPagesConfigs,
    ...connectionRootPagesConfigs,
    ...voiceChildPagesConfigs,
    ...profileConfig,
  ],
};

const unAuthorizedStructure = {
  fallbackPath: '/home',
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    ...homePagesConfigs,
    ...voiceRootPagesConfigs,
    ...connectionChildPagesConfigs,
    {
      path: '/terms-and-conditions',
      element: <TermAndCondition />,
    },
    {
      path: '/support',
      element: (
        <Row>
          <Col md={12} offset={6} style={{paddingTop: 32}}>
            <SupportPage />
          </Col>
        </Row>
      ),
    },
    {
      path: '*',
      element: <Error404 />,
    },
  ]),
};

export {anonymousStructure, authorizedStructure, unAuthorizedStructure};
