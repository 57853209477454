import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppEnums';

const ConnectionPage = React.lazy(() => import('./NetworkPage'));
const GroupDetailPage = React.lazy(() => import('./GroupDetailPage'));

export const connectionRootPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/network',
    element: <ConnectionPage />,
  },
];

export const connectionChildPagesConfigs = [
  {
    path: '/network/community/:id',
    element: <GroupDetailPage />,
  },
];
