/* eslint-disable react/prop-types */
import {Col, Row, Select, Spin} from 'antd';
import {useEffect, useState} from 'react';
import {getDataApi, postDataApi} from '../../../../@crema/utility/APIHooks';
import {useAuthMethod} from '../../../../@crema/utility/AuthHooks';
import GiftDetailModal from './GiftDetailModal';
import GiftItem from './GiftItem';
import RedeemSuccessModal from './RedeemSuccessModal';

const GiftPage = () => {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState(null);
  const [detailModalVisible, setDetailModalVisible] = useState(null);
  const [redeem, setRedeem] = useState(null);

  const {getUserProfile} = useAuthMethod();

  const [gifts, setGifts] = useState([]);

  const getGifts = async () => {
    setLoading(true);
    const res = await getDataApi('/gifts');
    if (res) {
      setGifts(res);
    }
    setLoading(false);
  };

  const onCategoryChanged = (val) => {
    setCategory(val);
  };

  const confirmRedeem = async (id) => {
    await postDataApi(
      '/redeems',
      {
        giftId: id,
      },
      {
        fetchSuccess: (res) => {
          getUserProfile();
          setRedeem({
            ...res,
            gift: gifts.find((x) => x.id === id),
          });
        },
      },
    );
  };

  useEffect(() => {
    getGifts();
  }, []);

  return (
    <>
      <Select
        placeholder='All Categories'
        allowClear
        style={{width: '200px'}}
        className='mb-24'
        onChange={onCategoryChanged}
        value={category}
        optionFilterProp='children'>
        {['F&B', 'Product', 'Service']?.map((e) => (
          <Select.Option value={e} key={e}>
            {e}
          </Select.Option>
        ))}
      </Select>

      <Spin spinning={loading}>
        <Row gutter={16} className='d-100'>
          {gifts
            .filter((x) => x.category.includes(category || ''))
            .map((x) => (
              <Col md={8} xs={24} key={x.id}>
                <GiftItem
                  data={x}
                  connection={x.connection}
                  hasMessage={true}
                  visitProfile={false}
                  hasMore={true}
                  connectionId={x.connectionId}
                  hasRedeem={true}
                  onImageClick={() => setDetailModalVisible(x)}
                  onRedeemed={() => confirmRedeem(x.id)}
                />
              </Col>
            ))}
        </Row>
      </Spin>
      {detailModalVisible && (
        <GiftDetailModal
          gift={detailModalVisible}
          onCancel={() => setDetailModalVisible(null)}
          onCreate={() => setDetailModalVisible(null)}
          onRedeemClick={() => confirmRedeem(detailModalVisible.id)}
        />
      )}
      {redeem && (
        <RedeemSuccessModal redeem={redeem} onCancel={() => setRedeem(null)} />
      )}
    </>
  );
};

export default GiftPage;
