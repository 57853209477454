/* eslint-disable react/prop-types */
import {UserOutlined} from '@ant-design/icons';
import {Avatar} from 'antd';
import Text from 'antd/lib/typography/Text';
import {useAuthUser} from '../../../@crema/utility/AuthHooks';
import './index.style.less';
const SearchItem = ({user, isSelected = false, onRemove}) => {
  const {user: me} = useAuthUser();
  const destination = me.id === user.fromId ? user.to : user.from;

  return (
    <div className='search-item'>
      <Avatar
        size={40}
        icon={<UserOutlined />}
        src={destination?.avatar || ''}></Avatar>
      <div className='user-info'>
        <Text className='user-name'>{destination?.name || ''}</Text>
        <Text className='user-designation'>
          {destination?.profile?.contactInfo?.currentDesignation || ''}
        </Text>
      </div>
      {isSelected && (
        <div className='user-remove'>
          <i className='wc-icon icon-ic_close' onClick={onRemove}></i>
        </div>
      )}
    </div>
  );
};
export default SearchItem;
