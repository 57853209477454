/* eslint-disable react/prop-types */
import {Button, Card, Checkbox, Col, Form, Modal, Row, Spin, Tabs} from 'antd';
import {useEffect, useState} from 'react';
import jwtAxios from '../../../services/auth/jwt-auth/jwt-api';
import './index.style.less';
import {AppSpin} from '../../AppSpin';

const FilterModal = ({visible, data, onCancel, onApply}) => {
  const [content, setContent] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getContent = () => {
    jwtAxios
      .get('content')
      .then((res) => {
        setContent(res.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getContent();
  }, []);

  const [form] = Form.useForm();
  const onFinish = async (values) => {
    onApply(values);
  };

  return (
    <Modal
      className='filter-modal'
      title='Search Filter'
      visible={visible}
      onCancel={onCancel}
      destroyOnClose
      bodyStyle={{minHeight: 600}}
      width={'90%'}
      footer={
        <Row className='filter-modal-footer'>
          <Col xs={24} md={8}>
            <Button
              className='btn-clear'
              onClick={() =>
                form.setFieldsValue({
                  jobFunctionIds: [],
                  jobRoleIds: [],
                  socialCauseIds: [],
                  profileLifeStagesCareer: [],
                  profileLifeStagesFamily: [],
                  profileLifeStagesSelf: [],
                })
              }
              type='link'>
              clear all filter(s)
            </Button>
          </Col>
          <Col xs={24} md={8}>
            <Button type='primary' ghost onClick={onCancel}>
              DISCARD
            </Button>
            <Button type='primary' htmlType='submit' form='form'>
              APPLY
            </Button>
          </Col>
        </Row>
      }>
      <Spin spinning={isLoading} indicator={<AppSpin />}>
        <Form
          id='form'
          onFinish={onFinish}
          form={form}
          initialValues={{...data}}
          layout='vertical'>
          <Tabs defaultActiveKey='1' centered>
            <Tabs.TabPane key='1' tab='LIFE STAGE(S)'>
              <Row gutter={32}>
                <Col md={8} xs={24} className='mt-16'>
                  <Card title='Career' bordered>
                    <Form.Item name='profileLifeStagesCareer'>
                      <Checkbox.Group
                        options={content.lifeStages
                          ?.filter((x) => x.group === 'Career')
                          ?.map((x) => ({
                            value: x.id,
                            label: x.name,
                          }))}
                      />
                    </Form.Item>
                  </Card>
                </Col>
                <Col md={8} xs={24} className='mt-16'>
                  <Card title='Family' bordered>
                    <Form.Item name='profileLifeStagesFamily'>
                      <Checkbox.Group
                        options={content.lifeStages
                          ?.filter((x) => x.group === 'Family')
                          ?.map((x) => ({
                            value: x.id,
                            label: x.name,
                          }))}
                      />
                    </Form.Item>
                  </Card>
                </Col>
                <Col md={8} xs={24} className='mt-16 '>
                  <Card title='Self' bordered>
                    <Form.Item name='profileLifeStagesSelf'>
                      <Checkbox.Group
                        options={content.lifeStages
                          ?.filter((x) => x.group === 'Self')
                          ?.map((x) => ({
                            value: x.id,
                            label: x.name,
                          }))}
                      />
                    </Form.Item>
                  </Card>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane key='2' tab='JOB FUNCTION(S)'>
              <Row gutter={32}>
                <Col xs={24} md={4}></Col>
                <Col xs={24} md={16}>
                  <Form.Item name='jobFunctionIds'>
                    <Checkbox.Group
                      options={content.jobFunctions?.map((x) => ({
                        value: x.id,
                        label: x.name,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane key='3' tab='JOB ROLE'>
              <Row gutter={32}>
                <Col xs={24} md={4}></Col>
                <Col xs={24} md={16}>
                  <Form.Item name='jobRoleIds'>
                    <Checkbox.Group
                      options={content.jobRoles?.map((x) => ({
                        value: x.id,
                        label: x.name,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane key='4' tab='SOCIAL CAUSES'>
              <Row gutter={32}>
                <Col xs={24} md={4}></Col>
                <Col xs={24} md={16}>
                  <Form.Item name='socialCauseIds'>
                    <Checkbox.Group
                      options={content.socialCauses?.map((x) => ({
                        value: x.id,
                        label: x.name,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </Spin>
    </Modal>
  );
};

export default FilterModal;
