const getOptional = (key) => {
  return process.env[key];
};

export const AppConfigs = {
  awsAppSyncApikey: getOptional('REACT_APP_AWS_APPSYNC_APIKEY') || '',
  awsAppSyncGraphql: getOptional('REACT_APP_AWS_APPSYNC_GRAPHQL') || '',
  stage: getOptional('REACT_APP_STAGE') || 'dev',
  webUrl: getOptional('REACT_APP_WEB_URL') || 'https://mentoringsg.com',
  shareUrl:
    getOptional('REACT_APP_SHARE_URL') || 'https://share.mentoringsg.com',
  apiUrl: getOptional('REACT_APP_API_URL') || 'https://api.mentoringsg.com',
  adminRoleId: 2,
};
