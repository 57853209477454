import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppEnums';

const SearchPage = React.lazy(() => import('./SearchPage'));

export const searchPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/search',
    element: <SearchPage />,
  },
];
