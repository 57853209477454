/* eslint-disable react/prop-types */
import {Avatar, Button, Col, Modal, Row} from 'antd';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import {useEffect} from 'react';
import './index.style.less';

const RedeemSuccessModal = ({onCancel, redeem}) => {
  useEffect(() => {}, []);

  return (
    <>
      <Modal
        visible={true}
        onCancel={onCancel}
        destroyOnClose
        width={600}
        footer={
          <Row gutter={16}>
            <Col xs={8} style={{marginBottom: 12}} offset={8}>
              <Button key='back' type='primary' ghost block onClick={onCancel}>
                DONE
              </Button>
            </Col>
          </Row>
        }>
        <Row>
          <Col md={24} className='text-center'>
            <Avatar
              src='/assets/images/img_ticket.svg'
              shape='square'
              size={120}
            />
            <br />
            <br />
            <Text strong className='font-20 text-secondary'>
              REDEEM POINTS SUCCESSFULLY!
            </Text>
            <br />
            <br />

            <Text>
              Reward redemption request has been sent successfully, we will
              provide redemption code via email after approval.
            </Text>
            <br />
            <br />
            <br />

            <Text className='fw-600'>
              Reward Redemption Code is one-time use only.
            </Text>
            <br />
            <br />
            <div>
              <Text strong className='text-black font-16'>
                {redeem.gift?.name}
              </Text>
              <br />
              <Text className='font-12'>
                {moment(redeem.createdAt).format('MMMM DD, YYYY - h:mm A')}
              </Text>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default RedeemSuccessModal;
