/* eslint-disable react/prop-types */
import {Button, Card} from 'antd';
import Text from 'antd/lib/typography/Text';
import {Link, useNavigate} from 'react-router-dom';
import {postDataApi} from '../../../@crema/utility/APIHooks';
import './index.style.less';

const CommunityCard = ({group, onRefresh}) => {
  const navigate = useNavigate();

  const onJoinGroupClick = async () => {
    await postDataApi('/groups/requests', {
      groupId: group.id,
    });
    onRefresh?.();
  };

  const isJoined = !!group.groupMembers?.length;
  const isRequested = !!group.requestGroups?.filter(
    (x) => x.status === 'Pending',
  ).length;
  return (
    <Card className='community-card'>
      <div
        className='community-img'
        style={{
          backgroundImage: `url(${group.banner?.split(',')[0]})`,
        }}
      />
      <div className='community-body'>
        <Link to={`/network/community/${group.id}`}>
          <Text className='community-title btn-link'>{group.name}</Text>
        </Link>
        <span className='community-desc'>{group.description}</span>
        <div className='d-justify-center d-flex d-100'>
          {isJoined && (
            <Button
              onClick={() => navigate(`/network/community/${group.id}`)}
              type='primary'
              ghost>
              <i className='wc-icon icon-ic_check'></i>
              JOINED
            </Button>
          )}
          {isRequested && (
            <Button type='primary' ghost>
              <i className='wc-icon icon-ic_check'></i>
              REQUESTED
            </Button>
          )}
          {!isJoined && !isRequested && (
            <Button onClick={onJoinGroupClick} type='primary'>
              JOIN GROUP
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
};

export default CommunityCard;
