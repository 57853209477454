import {Button, Col, Row, Spin} from 'antd';
import Text from 'antd/lib/typography/Text';
import {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {getDataApi} from '../../../@crema/utility/APIHooks';
import {useLayoutActionsContext} from '../../../@crema/utility/AppContextProvider/LayoutContextProvider';
import {useAuthUser} from '../../../@crema/utility/AuthHooks';
import Footer from '../../../components/Footer';
import CityInfo from './CityInfo';
import FeaturedGroups from './CityInfo/FeaturedGroups';
import FeaturedMentors from './CityInfo/FeaturedMentors';
import './CityInfo/index.style.less';
import {AppSpin} from './../../../@crema/core/AppSpin/index';
import AppPageMetadata from '../../../@crema/core/AppPageMetadata';

const LandingPage = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const {setModalUserId} = useLayoutActionsContext();
  const [mentors, setMentors] = useState([]);
  const [loadingMentors, setLoadingMentors] = useState(true);
  const [groups, setGroups] = useState([]);
  const [loadingGroup, setLoadingGroups] = useState(true);
  const [cities, setCities] = useState([]);
  const [loadingCity, setLoadingCity] = useState(true);
  const {user} = useAuthUser();

  useEffect(() => {
    const profile = params.get('profile');
    if (profile) {
      setModalUserId(profile);
    }
  }, [params]);

  const onCreateVoiceClick = (isAnonymous) => {
    navigate(`/voice?type=${isAnonymous ? 'anonymous' : 'yourself'}`);
  };

  const onViewVoicesClick = () => {
    navigate(`/voice`);
  };

  const getCity = () => {
    getDataApi('content/banners', {
      take: 10,
    })
      .then((res) => {
        setCities(res.data?.filter((x) => !x.isXs));
        setLoadingCity(false);
      })
      .catch(() => setLoadingCity(false));
  };

  const getFeatureMentors = () => {
    getDataApi('/search/feature-mentors', {
      take: 12,
      skip: 0,
      userId: user?.id,
    })
      .then((res) => {
        setLoadingMentors(false);
        setMentors(res);
      })
      .catch(() => setLoadingMentors(false));
  };

  const getGroups = () => {
    getDataApi('groups/feature', {
      take: 9,
      skip: 0,
      userId: user?.id,
    })
      .then((res) => {
        setGroups(res.data);
        setLoadingGroups(false);
      })
      .catch(() => setLoadingGroups(false));
  };

  useEffect(() => {
    getCity();
    getFeatureMentors();
    getGroups();
  }, []);

  const onOpenBanner = (banner) => {
    if (banner?.link) {
      window.open(banner?.link, '_blank');
    }
  };

  return (
    <div>
      <AppPageMetadata />
      <Spin indicator={<AppSpin />} spinning={loadingCity}>
        <CityInfo cityData={cities} onClick={onOpenBanner} />
      </Spin>
      <div className='container mt-16'>
        {!!user && <Text className='page-subtitle'>Ask a Question</Text>}
        {user ? (
          <Row gutter={18} style={{marginTop: 24}}>
            <Col md={12} xs={24}>
              <Button
                type='primary'
                block
                onClick={() => onCreateVoiceClick(false)}>
                <i className='wc-icon icon-ic_yourself' />
                Ask as Yourself
              </Button>
            </Col>
            <Col md={12} xs={24}>
              <Button
                type='primary'
                ghost
                block
                onClick={() => onCreateVoiceClick(true)}>
                <i className='wc-icon icon-ic_anonymous' />
                Ask as Anonymous
              </Button>
            </Col>
          </Row>
        ) : (
          <Row gutter={18} justify='center' style={{marginTop: 24}}>
            <Col md={12} offset-md={6} xs={24}>
              <Button type='primary' block onClick={onViewVoicesClick}>
                <i className='wc-icon icon-ic_yourself' />
                View all voices
              </Button>
            </Col>
          </Row>
        )}

        <Text className='page-subtitle'>Featured Mentors</Text>
        <Spin spinning={loadingMentors}>
          <FeaturedMentors listMentors={mentors} />
        </Spin>
        <Text className='page-subtitle'>Featured Communities</Text>
        <Spin spinning={loadingGroup}>
          <FeaturedGroups listGroups={groups} onRefresh={getGroups} />
        </Spin>
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
