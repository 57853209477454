/* eslint-disable react/prop-types */
import {Button, Card, Space} from 'antd';
import Text from 'antd/lib/typography/Text';
import Avatar from '../../../../components/Avatar';
import './index.style.less';

const GiftItem = ({data, className, onImageClick, onRedeemed}) => {
  return (
    <Card className={`gift-card ${className}`}>
      <Avatar
        src={data.image}
        size={80}
        onClick={onImageClick}
        className='user-card-avatar'
        style={{marginTop: 16, marginRight: 16}}
      />
      <Text className='user-name btn-pointer' ellipsis onClick={onImageClick}>
        {data.name}
      </Text>
      <Text className='' ellipsis>
        {data.brand}
      </Text>
      <Text strong className='text-primary font-16 mb-16 mt-16'>
        {data.point} POINTS
      </Text>
      <Space className='row'>
        <Button type='ghost' danger onClick={onRedeemed}>
          Redeem
        </Button>
      </Space>
    </Card>
  );
};

export default GiftItem;
