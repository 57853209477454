import {List, Space} from 'antd';
import Text from 'antd/lib/typography/Text';
import PropTypes from 'prop-types';
import './NotificationItem.less';

import ReactMarkdown from 'react-markdown';
import {useNavigate} from 'react-router-dom';
import {getTimeAgoDisplay} from '../../utility/Utils';
import {useEffect, useState} from 'react';
import {patchDataApi} from '../../utility/APIHooks';

export const NotificationType = {
  Profile: 'Profile',
  VoiceComment: 'Voices Comment',
  VoiceCommentAnswer: 'Voices Comment|Answer',
  VoiceCommentVoice: 'Voices Comment|Voice',
  Voice: 'Voices',
  VoiceApprove: 'Voices|Approve',
  MentorAppointment: 'Mentor Appointment',
  ConnectionRequest: 'Connection Request',
  ConnectionRequestAccept: 'Connection Request|Accept',
  ConnectionRequestReject: 'Connection Request|Reject',
  MenteeRequest: 'Mentee Request',
  MenteeRequestAccept: 'Mentee Request|Accept',
  MenteeRequestReject: 'Mentee Request|Reject',
  MentorRequest: 'Mentor Request',
  MentorRequestAccept: 'Mentor Request|Accept',
  MentorRequestApprove: 'Mentor Request|Approve',
  MentorRequestReject: 'Mentor Request|Reject',
  GroupInvitation: 'Group Invitation',
  GroupInvitationInvite: 'Group Invitation|Invite',
  GroupRequest: 'Group Request',
  GroupRequestAccept: 'Group Request|Accept',
  GroupRequestApprove: 'Group Request|Approve',
  GroupRequestSent: 'Group Request|Sent',
  SessionScheduling: 'Session Scheduling',
  SessionSchedulingAccept: 'Session Scheduling|Accept',
  SessionSchedulingCancel: 'Session Scheduling|Cancel',
  SessionSchedulingSent: 'Session Scheduling|Sent',
  Reward: 'Reward',
  RewardConnection: 'Reward|Connection',
  RewardRedeemAccept: 'Reward|RedeemAccept',
  RewardCompletedMentorship: 'Reward|CompleteMentorship',
  RewardFirstMessage: 'Reward|FirstMessage',
  RewardFirstSession: 'Reward|FirstSession',
  RewardAskVoice: 'Reward|AskVoice',
  RewardAnswerVoice: 'Reward|AnswerVoice',
  RewardCreateGroup: 'Reward|CreateGroup',
  RewardCompleteOnboarding: 'Reward|CompleteOnboarding',
};

const NotificationItem = (props) => {
  const {item} = props;

  const icons = {
    [NotificationType.Profile]: 'ic_profile',
    [NotificationType.MenteeRequest]: 'ic_network',
    [NotificationType.MentorRequest]: 'ic_network',
    [NotificationType.ConnectionRequest]: 'ic_network',
    [NotificationType.GroupInvitation]: 'ic_network',
    [NotificationType.GroupInvitationInvite]: 'ic_network',
    [NotificationType.GroupRequest]: 'ic_network',
    [NotificationType.GroupRequestApprove]: 'ic_network',
    [NotificationType.GroupRequestAccept]: 'ic_network',
    [NotificationType.GroupRequestSent]: 'ic_network',
    [NotificationType.Voice]: 'ic_message',
    [NotificationType.VoiceApprove]: 'ic_message',
    [NotificationType.VoiceComment]: 'ic_message',
    [NotificationType.VoiceCommentAnswer]: 'ic_message',
    [NotificationType.VoiceCommentVoice]: 'ic_message',
    [NotificationType.SessionScheduling]: 'ic_calendar',
    [NotificationType.SessionSchedulingAccept]: 'ic_calendar',
    [NotificationType.SessionSchedulingCancel]: 'ic_calendar',
    [NotificationType.SessionSchedulingSent]: 'ic_calendar',
    [NotificationType.Reward]: 'ic_gift_outline',
    [NotificationType.RewardConnection]: 'ic_gift_outline',
    [NotificationType.RewardRedeemAccept]: 'ic_gift_outline',
  };

  const colors = {
    [NotificationType.Profile]: '#0A66C2',
    [NotificationType.MenteeRequest]: '#C00000',
    [NotificationType.MentorRequest]: '#C00000',
    [NotificationType.ConnectionRequest]: '#C00000',
    [NotificationType.GroupInvitation]: '#C00000',
    [NotificationType.GroupInvitationInvite]: '#C00000',
    [NotificationType.GroupRequestAccept]: '#C00000',
    [NotificationType.GroupRequestApprove]: '#C00000',
    [NotificationType.GroupRequest]: '#C00000',
    [NotificationType.GroupRequestSent]: '#C00000',
    [NotificationType.Voice]: '#D3954D',
    [NotificationType.VoiceApprove]: '#D3954D',
    [NotificationType.VoiceComment]: '#D3954D',
    [NotificationType.VoiceCommentAnswer]: '#D3954D',
    [NotificationType.VoiceCommentVoice]: '#D3954D',
    [NotificationType.SessionScheduling]: '#64AC07',
    [NotificationType.SessionSchedulingAccept]: '#64AC07',
    [NotificationType.SessionSchedulingCancel]: '#64AC07',
    [NotificationType.SessionSchedulingSent]: '#64AC07',
    [NotificationType.Reward]: '#0A66C2',
    [NotificationType.RewardConnection]: '#0A66C2',
    [NotificationType.RewardRedeemAccept]: '#0A66C2',
  };

  const navigate = useNavigate();

  const onItemClick = (item) => {
    readNotification();
    switch (item.type) {
      case NotificationType.Profile:
        navigate(`/profile`);
        break;
      case NotificationType.MenteeRequest:
      case NotificationType.MentorRequestAccept:
        navigate(`/network?type=Mentor`);
        break;
      case NotificationType.ConnectionRequest:
      case NotificationType.ConnectionRequestAccept:
        navigate(`/network?type=Connection`);
        break;
      case NotificationType.MentorRequest:
      case NotificationType.MenteeRequestAccept:
        navigate(`/network?type=Mentee`);
        break;
      case NotificationType.GroupInvitationInvite:
        navigate(`/network?type=Group`);
        break;
      case NotificationType.GroupRequestAccept:
      case NotificationType.GroupRequestSent:
      case NotificationType.GroupRequestApprove:
        navigate(`/network/community/${item.itemId}`);
        break;
      case NotificationType.VoiceComment:
      case NotificationType.VoiceCommentAnswer:
      case NotificationType.VoiceCommentVoice:
      case NotificationType.VoiceApprove:
        navigate(`/voice/${item.itemId}`);
        break;
      case NotificationType.SessionScheduling:
      case NotificationType.SessionSchedulingAccept:
      case NotificationType.SessionSchedulingCancel:
        navigate(`/calendar`);
        break;
      case NotificationType.SessionSchedulingSent:
        navigate(`/calendar?type=invitation`);
        break;
      case NotificationType.MentorRequestApprove:
        navigate(`/my-profile`);
        break;
      case NotificationType.RewardConnection:
      case NotificationType.RewardFirstMessage:
      case NotificationType.RewardCompleteOnboarding:
      case NotificationType.RewardCompletedMentorship:
      case NotificationType.RewardAnswerVoice:
      case NotificationType.RewardAskVoices:
      case NotificationType.RewardCreateGroup:
      case NotificationType.RewardFirstSession:
        navigate(`/my-profile`);
        break;
      case NotificationType.RewardRedeemAccept:
        navigate(`/reward?t=history`);
        break;
    }
  };

  const readNotification = async () => {
    setIsRead(true);
    await patchDataApi(`/notifications/${item.id}/read`, {isRead: true});
  };

  const [isRead, setIsRead] = useState(false);

  useEffect(() => {
    if (item.isRead) {
      setIsRead(true);
    }
  }, [item.isRead]);

  return (
    <List.Item
      className={`notify-listItem item-hover ${
        isRead && 'notify-listItem__read'
      }`}>
      <List.Item.Meta
        onClick={() => onItemClick(item)}
        title={
          <div className='row d-space-between'>
            <Space className='mt-8'>
              <i
                className={`wc-icon icon-${icons[item.topic]}`}
                style={{color: colors[item.topic]}}
              />
              <Text style={{fontSize: 14, color: colors[item.topic]}}>
                {item.topic}
              </Text>
            </Space>
            <Text style={{fontSize: 14, color: '#808080'}}>
              {getTimeAgoDisplay(item.createdAt)}
            </Text>
          </div>
        }
        description={
          <Space style={{color: '#333', marginTop: 8, fontSize: 16}}>
            <ReactMarkdown>{item.description}</ReactMarkdown>
          </Space>
        }
      />
    </List.Item>
  );
};

export default NotificationItem;

NotificationItem.propTypes = {
  item: PropTypes.object.isRequired,
};
