/* eslint-disable react/prop-types */
import {Image} from 'antd';
import './index.style.less';

const EmptyPlaceholder = ({
  image,
  description,
  extra,
  style,
  imageStyle,
  textStyle,
}) => {
  return (
    <div
      className=''
      style={{
        paddingTop: '50px',
        minHeight: 400,
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        ...style,
      }}>
      <Image
        preview={false}
        width={96}
        className='mb-32'
        style={imageStyle}
        src={image || '/assets/images/search.png'}
      />
      <div
        style={textStyle}
        className={`ml-32 mr-32 mt-24 t-center`}
        dangerouslySetInnerHTML={{
          __html: description?.replace(/\n/g, '<br />'),
        }}></div>
      {!!extra && (
        <div
          style={textStyle}
          className='ml-32 mr-32'
          dangerouslySetInnerHTML={{
            __html: extra?.replace(/\n/g, '<br />'),
          }}></div>
      )}
    </div>
  );
};

export default EmptyPlaceholder;
