import {Button, Col, Form, Row, Spin} from 'antd';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuthUser} from '../../../../@crema/utility/AuthHooks';
import Avatar from '../../../../components/Avatar';
import RedeemsPage from '../Redeem';
import './index.style.less';
import RewardCard from './RewardCard';

const RewardPage = () => {
  const {user} = useAuthUser();
  const navigate = useNavigate();

  const [isLoading] = useState(false);

  return (
    <Spin spinning={isLoading}>
      <Row gutter={32} style={{paddingBottom: 80}}>
        <Col xs={24}></Col>
        <Col md={6} xs={24}>
          <Button
            type='link'
            className='pl-0 mb-16'
            onClick={() => navigate(-1)}>
            <span className='we-icon icon-ic_arrow-left mr-16'></span>
            <span className='pl-16'>BACK</span>
          </Button>
          <Form.Item className='info-upload'>
            <Avatar
              className='info-upload-avatar'
              hasBadge
              size={236}
              user={user}
              src={user?.avatar}
            />
          </Form.Item>
          <RewardCard />
        </Col>
        <Col md={18}>
          <div className='text-center'>
            <h3 className='text-secondary font-30'>REWARD</h3>
            <p className=''>
              Earn points from your activity to upgrade member and get the
              rewards
            </p>
          </div>
          <RedeemsPage />
        </Col>
      </Row>
    </Spin>
  );
};

export default RewardPage;
