/* eslint-disable react/prop-types */
import {Button, Card, Col, Image, Progress, Row, Space} from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuthUser} from '../../../../@crema/utility/AuthHooks';
import './index.style.less';
import RewardFAQ from './RewardFAQ';

const RewardCard = ({hasBtnRedeem = false}) => {
  const {user} = useAuthUser();
  const navigate = useNavigate();
  const [hasFaq, setHasFaq] = useState(false);

  const getRequestMentor = async () => {};

  useEffect(() => {
    getRequestMentor();
  }, []);

  const nextPoint =
    [50000, 100000, 10000000].filter((x) => x > user?.totalPoint)?.[0] ||
    1000000000;

  return (
    <Card className='reward-card'>
      <div>
        <Row className='mb-0'>
          <Col xs={20} className='mb-0'>
            <Title level={3}>REDEEMABLE POINTS</Title>
          </Col>
          <Col xs={4} className='d-flex d-flex-end reward-card__d-info mb-0'>
            <Button type='link' shape='circle' onClick={() => setHasFaq(true)}>
              <span className='wc-icon icon-icon_info font-22' />
            </Button>
          </Col>
        </Row>
        <Col xs={24} className='mb-0'>
          <Space>
            <Image preview={false} src='/assets/images/ic_point_coin.svg' />
            <Title className='reward-card__point-lg'>{user.balance}</Title>
          </Space>
        </Col>
        <Col xs={24} className='p-0'>
          <Space>
            <Text className='reward-card__text'>Your total earned points</Text>
            <Image
              preview={false}
              className='reward-card__coin-xs'
              src='/assets/images/ic_point_coin_s.svg'
            />
            <Text className='reward-card__point-xs'>{user.totalPoint}</Text>
          </Space>
        </Col>
        <Row className='d-100'>
          <Col xs={24}>
            <Row>
              <div className='reward-card__progress'>
                <Progress
                  percent={(user.totalPoint / nextPoint) * 100}
                  status='active'
                  showInfo={false}
                />
              </div>
              <Text className='reward-card__point-max'>{nextPoint}</Text>
            </Row>
          </Col>
        </Row>
        <Col xs={24}>
          <Row className='mt-8'>
            <Text className='reward-card__text pr-4'>Get more</Text>
            <Image
              preview={false}
              className='reward-card__coin-xs'
              src='/assets/images/ic_point_coin_s.svg'
            />
            <Text className='reward-card__point-xs2 pl-4'>
              {nextPoint - user.totalPoint}
            </Text>
            <Text className='reward-card__text pl-4'> to unlock next tier</Text>
          </Row>
        </Col>
        {hasBtnRedeem && (
          <Col xs={24}>
            <Text></Text>
            <br />
            <Button type='primary' onClick={() => navigate('/reward')}>
              REDEEM POINTS
              <span className='wc-icon icon-ic_arrow-right'></span>
            </Button>
          </Col>
        )}
      </div>
      <Row>{hasFaq && <RewardFAQ onCancel={() => setHasFaq(false)} />}</Row>
    </Card>
  );
};

export default RewardCard;
