import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppEnums';

const NotificationPage = React.lazy(() => import('./NotificationPage'));

export const notificationPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/notification',
    element: <NotificationPage />,
  },
];
