const routesConfig = [
  {
    id: 'home',
    title: 'Home',
    messageId: 'sidebar.search',
    path: '/home',
    type: 'item',
    icon: 'icon-ic_home',
  },
  {
    id: 'voice',
    title: 'Voices',
    messageId: 'sidebar.connection',
    path: '/voice',
    type: 'item',
    icon: 'icon-ic_message',
  },
  {
    id: 'network',
    title: 'Network',
    messageId: 'sidebar.connection',
    path: '/network',
    type: 'item',
    icon: 'icon-ic_network',
  },
  {
    id: 'calendar',
    title: 'Appointment',
    messageId: 'sidebar.connection',
    path: '/calendar',
    type: 'item',
    icon: 'icon-ic_calendar',
  },
  {
    id: 'chat',
    title: 'Chat',
    messageId: 'sidebar.chat',
    path: '/chat',
    type: 'item',
    icon: 'icon-ic_chat',
  },
];
export default routesConfig;
